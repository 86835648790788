<template>
  <div :class="className" :style="{ height: height, width: width }"></div>
  <!-- <div :id="id" :style="style"></div> -->
</template>
<script>
import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
export default {
  data() {
    return {
      // echarts实例
      chart: '',
      option: {
        title: {
          text: '',
          textStyle: {
            fontWeight: 'bold',
            fontSize: 18,
            color: '#333',
          },
          left: 'center',
        },
        // 颜色类型
        // legend: {
        //   orient: 'vertical',
        //   left: 10,
        //   data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
        // },
        tooltip: {
          trigger: 'item',
          // {a}（系列名称），{b}（数据项名称），{c}（数值）, {d}（百分比）
          formatter: '{b} ({d}%)',
          // formatter: '{b}: {c}<br />{b}: {c}',
        },
        grid: {
          top: 100,
          containLabel: true,
        },
        series: [
          {
            name: '资产总览',
            type: 'pie',
            radius: ['50%', '60%'],
            top: '20%',
            data: [
              { name: '已完结的项目：', value: 20 },
              { name: '预约中的项目：', value: 5 },
              { name: '收益中的项目：', value: 5 },
            ],
            label: {
              normal: {
                position: 'outer',
                alignTo: 'edge',
                margin: 150,
                edgeDistance: 100,
                show: true,
                lineHeight: 16,
                width: '100',
                overflow: 'breakAll',
              },
              labelLine: {
                length: 25,
                showAbove: true,
                length: 40,
                length2: 0,
                maxSurfaceAngle: 80
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold',
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            // 此系列自己的调色盘。
            // color: ['#81D8D0', '#38B6E1', '#81D8D0']
            color: ['#81D8D0', '#020202', '#8BD8EF'],
          },
        ],
      },
    };
  },
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '550px',
    },
    height: {
      type: String,
      default: '250px',
    },
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: '',
    },
    gotoURL: {
      type: String,
      default: '',
    },
  },
  computed: {
    style() {
      return {
        height: this.height,
        width: this.width,
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 横轴数据
      // let xData = this.chartData.xData;
      // // 系列值
      // let sData = this.chartData.sData;
      // for (let i = 0; i < xData.length; i++) {
      //   // 此处使用let是关键，也可以使用闭包。原理不再赘述
      //   setTimeout(() => {
      //     this.option.xAxis.data.push(xData[i]);
      //     this.option.series[0].data.push(sData[i]);
      //   }, 1000 * i); // 此处要理解为什么是1000*i
      // };
      this.option.title.text = this.title;
      this.option.series[0].name = this.title;
      this.chart = echarts.init(this.$el, 'macarons');
      this.chart.setOption(this.option);
      let that = this;
      if (that.gotoURL) {
        // that.chart.on('click', function (params) {
        //   that.$router.push({ path: that.gotoURL }).catch(error => { });
        // });
        that.chart.on('click', (params) => {
          that.$router.push({ path: that.gotoURL }).catch((error) => { });
        });
      }
      window.addEventListener('resize', () => {
        this.chart.resize();
      });
    },
  },
  watch: {
    chartData(value) {
      let color = ['#81D8D0', '#020202', '#8BD8EF'];
      value.map((item, index) => {
        item.label = {
          color: color[index],
        };
      });
      this.option.series[0].data = value;
      this.chart.setOption(this.option);
      // for (let i = 0; i < xData.length; i++) {
      //   setTimeout(() => {
      //     this.option.xAxis.data.push(xData[i]);
      //     console.log(xData);
      //     this.option.series[0].data.push(sData[i]);
      //   }, 1000 * i);
      // };
    },
    title(value) {
      this.option.title.text = this.title;
      this.chart.setOption(this.option);
      // for (let i = 0; i < xData.length; i++) {
      //   setTimeout(() => {
      //     this.option.xAxis.data.push(xData[i]);
      //     console.log(xData);
      //     this.option.series[0].data.push(sData[i]);
      //   }, 1000 * i);
      // };
    },
  },
};
</script>
<style>
.chart {
  width: 48%;
  height: 400px;
}
</style>
